import React from 'react';
import "./TestimonialCard.scss";

const TestimonialCard = ({ data }) => {
    return (
        <div className="testimonial-card">
            <div className="testimonial-card-quote">
                <div className="testimonial-card-quoation-mark">“</div>
                <p style={{minHeight: "300px"}}>{data.text}</p>
                <div className="testimonial-stars">
                    <span>★ ★ ★ ★ ★</span>
                </div>
            </div>
            <hr className="testimonial-card-href"/>
            <div className="testimonial-card-author">
                <img src={data.companyLogo} alt="Company Logo" className="author-image" />
                <div className="testimonial-card-author-info">
                    <p className="testimonial-card-bigger-text">{data.author}</p>
                    <p>{data.position}<br />
                        {data.company.isLink ? (
                            <a href={data.company.href} target="_blank" rel="noopener noreferrer">
                                {data.company.name}
                            </a>
                        ) : (
                            data.company.name
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default TestimonialCard;