import React, { useState } from 'react';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import "./Form.scss";

const Form = ({ data, onChange, formSubmitted, onFormSubmit }) => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const validate = () => {
        setIsSubmitted(true);

        if (!data.name || !data.email) {
            return;
        }

        onFormSubmit(true);
        window.open("https://coalshop.cz/wp-content/uploads/2024/06/E-book-coalios-ERP-2024.pdf", "_blank");
    };

    return (
        <div className={`form-container ${formSubmitted ? 'empty-container' : ''}`}>
            <div className={`form-header-text ${formSubmitted ? 'hidden' : ''}`}>
                {
                    formSubmitted ? (
                        "Vyplnění bylo úspěšné!"
                    ) : (
                        "Vyplňte krátký formulář a získejte e-book zdarma plný tipů, jak získat náskok před konkurencí díky vhodnému ERP systému!"
                    )
                }
            </div>
            <div className="contact-box contact-label">
                <div className={`contact-box contact-label ${formSubmitted ? 'hidden' : ''}`}>
                    <div className="input-box">
                        <label for="name"><b>Jméno a příjmení</b></label>
                        <input value={data.name} onChange={(e) => onChange(e.target.value, "name")} className={`input-field ${isSubmitted && !data.name ? 'input-error' : ''}`} type="text" name="name" required />
                    </div>
                    <div className="input-box">
                        <label for="email"><b>E-mail</b></label>
                        <input value={data.email} onChange={(e) => onChange(e.target.value, "email")} className={`input-field ${isSubmitted && !data.email ? 'input-error' : ''}`} type="email" name="email" required />
                    </div>
                    <div className="d-flex flex-row gdpr-panel">
                        <input checked={data.gdpr} onChange={(e) => onChange(e.target.checked, "gdpr")} type="checkbox" className={`gdpr-checkbox ${isSubmitted && !data.gdpr ? 'input-error' : ''}`} />
                        <FormattedHTMLMessage id="contact.gdpr" />
                    </div>
                </div>

                <button style={{ marginTop: "15px" }} className="btn-form" type="button" onClick={validate}>
                    {
                        formSubmitted ? "Stáhnout" : "Stáhnout e-book zdarma!"
                    }
                </button>
            </div>
        </div>
    )
}

export default Form;