import React, { useState } from "react";
import "./Ebook.scss";
import EbookButton from "./EbookButton";
import KalatEbook from "../../images/kalat-ebook.png";
import VyhodyEbook from "../../images/ebook-vyhody.png";
import PartakEbook from "../../images/ebook-partak.png";
import TymEbook from "../../images/ebook-tym.png";
import InovaceEbook from "../../images/ebook-inovace.png";
import TestimonialCard from "./TestimonialCard";
import KubanEbook from "../../images/ebook-kuban.jpg";
import PetrusEbook from "../../images/ebook-petrus.png";
import DKlimaEbook from "../../images/ebook-dklima.png";
import GNutritionEbook from "../../images/ebook-gnutrition.png";
import UvodkaEbook from "../../images/ebook-uvodka.png";
import Form from "./Form";

const TESTIMONIAL_CARDS = [
    {
        text: "Coalios nám velice kvalitně a rychle pomohl s propojením našeho e-shopu vzduchotechnika.cz na ERP systém HELIOS iNuvio. S panem Cvejnem a jeho kolegy byla spolupráce a komunikace velice příjemná a zdolali jsme všechny možné překážky. Díky tomu bude naše práce více automatizovaná a hlavně efektivní. Děkujeme společnosti coalios, kterou můžeme jednoznačně doporučit ostatním",
        author: "Jan Dorazil",
        position: "Jednatel společnosti",
        company: {
            isLink: false,
            name: "D - Klima s.r.o.",
        },
        companyLogo: DKlimaEbook,
    },
    {
        text: "Coalios nám pomohl s propojením ERP systému HELIOS s naším novým e-shopem AMBeauty.cz. Spolupráce s pány z coaliosu byla velice příjemná, pomáhali nám s překážkami i v procesu nastavování tak, aby byla naše práce bezproblémová a maximálně efektivní. Děkujeme a společnost coalios doporučujeme!",
        author: "Matěj Motyčka",
        position: "Marketingový manažer",
        company: {
            isLink: false,
            name: "Global Nutrition",
        },
        companyLogo: GNutritionEbook,
    },
    {
        text: "Coalios nám promptně a kvalitně pomohl vyřešit důležitou integraci mezi e-shopem Asklepion a účetním systémem HELIOS. Nyní můžeme objednávky elektronických voucherů z e-shopu odbavovat automatizovaně, což nám šetří velké množství manuální práce, snižuje chybovost a zrychluje proces doručení voucheru klientovi. Na základě této zkušenosti můžeme spolupráci s coalios doporučit.",
        author: "Martin Kubáň",
        position: "Ředitel marketingu",
        company: {
            isLink: false,
            name: "Asklepion",
        },
        companyLogo: KubanEbook,
    },
    {
        text: "Těšíme se z další reference našeho spokojeného zákazníka! E-shop AMBeauty od společnosti Global Nutrition jsme propojili s ERP systémem HELIOS. Tato důležitá integrace ušetří našemu zákazníkovi, který spolupracuje s Taťánou Kuchařovou, spoustu času a zefektivní práci.",
        author: "Global Nutrition",
        position: "Generální ředitelství",
        company: {
            isLink: false,
            name: "e-shop AMBeauty",
        },
        companyLogo: GNutritionEbook,
    },
    {
        text: "Nedávno jsme implementovali propojení mezi HELIOS iNuvio a Shoptetem a musím říct, že naše zkušenost byla naprosto vynikající. Proces nasazení byl rychlý. Díky skvělému týmu odborníků, kteří nám byli k dispozici, jsme se velmi rychle dostali do plného provozu. Implementace proběhla bez jakýchkoli komplikací, a to za pouhých 21 dní! Od počátečního kontaktu až po konečné doladění všech detailů jsme byli neustále informováni o průběhu práce. Naše dotazy byly rychle a ochotně zodpovězeny. Co se týče samotného uživatelského rozhraní, tak je neuvěřitelně uživatelsky přívětivé. Naše týmy si rychle osvojily nové funkce a integrace proběhla bez nutnosti zdlouhavého školení. Všechno je intuitivní a snadno ovladatelné, což nám šetří čas a zvyšuje efektivitu práce. Celkově jsme s propojením maximálně spokojeni. Doporučujeme ho všem, kteří hledají spolehlivé a efektivní řešení pro svůj e-commerce podnik.",
        author: "Jan Petrus",
        position: "Ředitel marketingu a zástupce ředitele",
        company: {
            isLink: true,
            name: "SURTEP, s. r. o. | www.barvylakydrogerie.cz",
            href: "https://www.surtep.cz/",
        },
        companyLogo: PetrusEbook,
    },
];

const Ebook = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        gdpr: false,
    });

    const [isSent, setIsSent] = useState(false);

    const handleChange = (value, prop) => {
        setFormData({ ...formData, [prop]: value });
    };

    const handleScroll = () => {
        if (!isSent) {
            const targetSection = document.getElementById("ebook-form");
            window.scrollTo({
                top: targetSection.offsetTop - 70,
                behavior: 'smooth',
            });
            return;
        }

        window.open("https://coalshop.cz/wp-content/uploads/2024/06/E-book-coalios-ERP-2024.pdf", "_blank");
    }

    return (
        <section id="ebook" className="page-section" style={{ marginTop: 0, paddingTop: 0 }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center ebook-image-container">
                        <img className="ebook-header-image img-fluid" src={UvodkaEbook} alt="ERP System E-book" />
                        <button onClick={handleScroll} className="image-download-button header-btn">Stáhnout e-book zdarma!</button>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            Udržte si perfektní přehled ve skladech. Vyřizujte objednávky v e-shopu během pár minut. Zapomeňte na ruční přepisování dokumentů a minimalizujte chyby. A co je nejdůležitější, mějte všechna klíčová data, od stavu zásob po faktury, na jednom místě. To je jen začátek toho, co vám přinese ERP systém – vaše tajná zbraň, díky níž:
                        </div>
                        <div className="page-text margin-bot">
                            <ul>
                                <li>
                                    získáte náskok před konkurencí,
                                </li>
                                <li>
                                    ušetříte ročně spoustu času a peněz,
                                </li>
                                <li>
                                    urychlíte všechny procesy vašeho podnikání,
                                </li>
                                <li>
                                    a hlavně otevřete dveře k rychlému růstu vaší firmy.
                                </li>
                            </ul>
                        </div>
                        <div className="page-text margin-bot">
                            <b>Jak ale vybrat ten správný systém?</b> Odpověď najdete <b>v našem e-booku „Jak správný ERP systém zefektivní vaše podnikání v každém směru!“</b>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <EbookButton onClick={handleScroll} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="testimonial-container">
                            <div className="testimonial-content">
                                <img src={KalatEbook} alt="Jakub Kalát" className="testimonial-image" />
                                <div className="testimonial-text">
                                    <div className="testimonial-quote">„Jste připraveni prozkoumat výhody moderního podnikového ERP systému pro vaši firmu? V našem e-booku vám ukážeme, jak komplexní podnikový systém může řešit výzvy vašeho rostoucího podniku a zajistit, že vaše IT infrastruktura bude růst s vámi! Buďte připraveni na změny, které vám přinesou jistotu a růst. Těšíme se na spolupráci!“</div>
                                    <p className="testimonial-author">
                                        <b>Jakub Kalát</b><br />
                                        výkonný ředitel, coalios s. r. o.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="ebook-list-container">
                            <div className="ebook-text">
                                <h2>3 klíčové otázky, <br /> které vám náš e-book zodpoví</h2>
                                <ol>
                                    <li>
                                        <b>Co je ERP systém a jaké jsou jeho hlavní výhody?</b>
                                        <ul>
                                            <li>Zjistěte, proč je ERP systém klíčem k efektivnímu řízení vaší firmy.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Jak poznáte, že je čas investovat do ERP systému?</b>
                                        <ul>
                                            <li>Naučte se rozpoznat signály, které ukazují, že vaše firma potřebuje moderní řešení.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Jaké jsou kroky k úspěšnému zavedení ERP systému?</b>
                                        <ul>
                                            <li>Prozkoumejte postup, který vám zajistí hladké zavedení ERP systému do vašich procesů.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div className="ebook-image">
                                <img src={VyhodyEbook} alt="E-book" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div style={{ marginTop: "15px", marginBottom: "15px" }} className="col-12 col-lg-8">
                        <EbookButton onClick={handleScroll} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="why-us-section">
                            <h2>A proč věřit právě našemu slovu?</h2>
                            <div className="why-us-container">
                                <div className="why-us-item">
                                    <img src={PartakEbook} alt="Silné partnerství" className="why-us-image" />
                                    <h3>Silné partnerství</h3>
                                    <p>Můžeme se pochlubit silným partnerstvím s Asseco Solutions, a.s., což nám poskytuje pevný základ a know-how v oblasti ERP systémů. HELIOS iNuvio je navíc jedním z nejrozšířenějších ERP systémů v celé České republice!</p>
                                </div>
                                <div className="why-us-item">
                                    <img src={InovaceEbook} alt="Inovativní řešení" className="why-us-image" />
                                    <h3>Inovativní řešení</h3>
                                    <p>Vyvinuli jsme vlastní e-commerce řešení coalshop konektor, které bezproblémově propojuje informační systém HELIOS s jakýmkoliv e-shopem, čímž zajišťujeme efektivní a moderní správu online prodejů.</p>
                                </div>
                                <div className="why-us-item">
                                    <img src={TymEbook} alt="Zkušený tým odborníků" className="why-us-image" />
                                    <h3>Zkušený tým odborníků</h3>
                                    <p>Náš tým se skládá z řady zkušených vývojářů a konzultantů, kteří mají hluboké znalosti a praxi v implementaci a optimalizaci ERP systémů pro různé typy podniků.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="testimonial-grid">
                            {
                                TESTIMONIAL_CARDS.map(card => (
                                    <TestimonialCard data={card} />
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div id="ebook-form" className="col-12 col-lg-8">
                        <Form onFormSubmit={setIsSent} formSubmitted={isSent} data={formData} onChange={handleChange} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Ebook